$blackish: #202020;
$blackishOP: rgba(32, 32, 32, 0.75);
$darkGrey: #3F3F3F;
$mediumGrey: #707070;
$eggYellow: #FFDF6C;
$white: #F5F5F5;

$cyan: #60daaa;
$red: #f45e61;
$blue: #0eb7da;

$thumbnailSize: 50px;