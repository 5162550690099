.buttons {
  isolation: isolate;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

button {
  cursor: pointer;
  box-sizing: border-box;
  color: #f45e61;
  font-size: inherit;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: .5em;
  padding: 1em 2em;
  font-weight: 700;
  position: relative;
  box-shadow: inset 0 0 0 2px #f45e61;
  outline: none !important;
}

button:before, button:after {
  box-sizing: inherit;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.spin {
  width: 4.5em;
  height: 4.5em;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 1em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.spin span {
  text-align: center;
  font-size: 13px;
}

.spin:hover {
  color: #60daaa;
}

.spin:before, .spin:after {
  top: 0;
  left: 0;
}

.spin:before {
  border: 2px solid rgba(0, 0, 0, 0);
}

.spin:hover:before {
  border-top-color: #60daaa;
  border-bottom-color: #60daaa;
  border-right-color: #60daaa;
  -webkit-transition: border-top-color .15s linear, border-right-color .15s linear .1s, border-bottom-color .15s linear .2s;
  -o-transition: border-top-color .15s linear, border-right-color .15s linear .1s, border-bottom-color .15s linear .2s;
  transition: border-top-color .15s linear, border-right-color .15s linear .1s, border-bottom-color .15s linear .2s;
}

.spin:after {
  border: 0 solid rgba(0, 0, 0, 0);
}

.spin:hover:after {
  border-top: 2px solid #60daaa;
  border-left-width: 2px;
  border-right-width: 2px;
  -webkit-transition: -o-transform .4s linear, -webkit-transform .4s linear, transform .4s linear, border-left-width 0s linear .35s;
  -o-transition: -o-transform .4s linear, -webkit-transform .4s linear, transform .4s linear, border-left-width 0s linear .35s;
  transition: -o-transform .4s linear, -webkit-transform .4s linear, transform .4s linear, border-left-width 0s linear .35s;
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.circle {
  box-shadow: none;
  border-radius: 100%;
}

.circle:before, .circle:after {
  border-radius: 100%;
}

.mixitup-control-active {
  outline: unset;
  color: #0eb7da;
}

.mixitup-control-active:focus, .mixitup-control-active:active {
  outline: unset;
}

.mixitup-control-active:after {
  border-top: 2px solid #0eb7da;
  border-left-width: 2px;
  border-right-width: 2px;
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mixitup-control-active:before {
  border-top-color: #0eb7da;
  border-bottom-color: #0eb7da;
  border-right-color: #0eb7da;
}

@font-face {
  font-family: B612;
  src: url("../B612-Regular.d63b8f49.woff") format("woff");
}

@font-face {
  font-family: morganite thin;
  src: url("../Morganite-Thin.bdafe8e7.woff") format("woff");
}

body {
  color: #f5f5f5;
  background-color: #202020;
  font-family: B612, "sans-serif";
  font-size: 14px;
}

body a {
  color: #0eb7da;
  padding-left: 5px;
  text-decoration: none;
}

body a:visited, body a:focus, body a:active, body a:link {
  text-decoration: none;
}

body h1, body h2, body h3, body h4, body h5, body h6 {
  text-align: center;
  letter-spacing: 3px;
  font-family: morganite thin, "sans-serif";
}

body h1.eggYellow, body h2.eggYellow, body h3.eggYellow, body h4.eggYellow, body h5.eggYellow, body h6.eggYellow {
  color: #ffdf6c;
}

body h1.mediumGrey, body h2.mediumGrey, body h3.mediumGrey, body h4.mediumGrey, body h5.mediumGrey, body h6.mediumGrey {
  color: #707070;
}

body h1.darkGrey, body h2.darkGrey, body h3.darkGrey, body h4.darkGrey, body h5.darkGrey, body h6.darkGrey {
  color: #3f3f3f;
}

body h1.white, body h2.white, body h3.white, body h4.white, body h5.white, body h6.white {
  color: #f5f5f5;
}

body h1.bg-mediumGrey, body h2.bg-mediumGrey, body h3.bg-mediumGrey, body h4.bg-mediumGrey, body h5.bg-mediumGrey, body h6.bg-mediumGrey {
  background-color: #707070;
}

body h1 {
  width: 90%;
  max-width: 400px;
  margin: 15px auto;
  font-size: 28px;
}

body .certif {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body .wrapper {
  width: 100%;
  max-width: 991px;
  margin-left: auto;
  margin-right: auto;
}

body .logo {
  width: 40%;
  max-width: 375px;
  margin: auto;
}

body .logo #name {
  fill: #0eb7da;
}

body .logo #tv {
  fill: #60daaa;
}

body .logo #dot {
  fill: #707070;
}

body .logo #ext {
  fill: #f5f5f5;
}

body table {
  width: 100%;
  margin-bottom: 10px;
}

body table tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body table tr td {
  width: 45%;
  border: 1px solid #707070;
  padding: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body table tr td + td {
  border-left: none;
}

body table tr td.first {
  width: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body table tr td.first img {
  width: 50px;
}

@media screen and (orientation: portrait) {
  body table tr td {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (orientation: landscape) {
  body table tr td {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

body table .thumbnail-container {
  width: 50px;
  height: 50px;
  background-color: #707070;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

body table .thumbnail-container .thumbnail {
  width: 50px;
  margin: auto;
}

body table .singleProgram {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin-left: 5%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body .overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(32, 32, 32, .75);
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

body .overlay.hidden {
  display: none;
}

body .overlay .content {
  width: 90%;
  max-width: 500px;
  color: #f5f5f5;
  background-color: #707070;
  margin: auto;
  padding: 5px;
  position: relative;
}

body .overlay .content .close {
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: #f5f5f5;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
}

body .overlay .content .close:before, body .overlay .content .close:after {
  content: " ";
  height: 24px;
  width: 3px;
  background-color: #f45e61;
  position: absolute;
  top: 6px;
  left: 17px;
}

body .overlay .content .close:before {
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

body .overlay .content .close:after {
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body .overlay .content .infos {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body .overlay .content .infos .illus {
  width: 50%;
}

body .overlay .content .infos .illus img {
  width: 100%;
}

body .overlay .content .infos .recap {
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 35px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body .overlay .content .infos .recap.full {
  width: 100%;
  padding-left: 0;
}

body .overlay .content .infos .recap .title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}

body .overlay .content .text {
  padding-top: 35px;
}

body .now table {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 10px;
}

body .now table td {
  width: 90%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

body footer {
  letter-spacing: 3px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body footer h2 {
  font-size: 40px;
}

body footer span {
  width: 100%;
  max-width: 400px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 15px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body footer form {
  margin: 35px auto;
}

body footer form .paypal {
  max-width: 100px;
}

body footer .mentions {
  margin-bottom: 35px;
}

body footer #legal-terms {
  text-align: left;
}

body .programsList .wrapper {
  max-width: 100vw;
  overflow: auto;
}

body .programsList .singleTd {
  min-width: 150px;
}

body .message {
  width: 400px;
  border-radius: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin: 45px auto;
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

body .message span {
  margin: 5px;
}

body .error {
  background-color: rgba(255, 0, 0, .2);
  border: 1px solid #8b0000;
}

body .success {
  max-width: 95vw;
  text-align: left;
  background-color: #006400;
  border: 1px solid green;
}

/*# sourceMappingURL=styles.css.map */
