.buttons {
  isolation: isolate;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

}
// Basic styles
button{
  outline: none !important;
  cursor: pointer;
  background: none;
  border: 0;
  box-sizing: border-box;
  margin: .5em;
  padding: 1em 2em;

  // Using inset box-shadow instead of border for sizing simplicity
  box-shadow: inset 0 0 0 2px $red;
  color: $red;
  font-size: inherit;
  font-weight: 700;

  // Required, since we're setting absolute on pseudo-elements
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.spin {
  width: 4.5em;
  height: 4.5em;
  padding: 1em;
  display: flex;
  justify-content: center;
  span{
    text-align: center;
    font-size: 13px;
  }
  &:hover {
    color: $cyan;
  }

  &::before,
  &::after {
    top: 0;
    left: 0;
  }

  &::before {
    border: 2px solid transparent; // We're animating border-color again
  }

  &:hover::before {
    border-top-color: $cyan; // Show borders
    border-right-color: $cyan;
    border-bottom-color: $cyan;

    transition:
            border-top-color 0.15s linear, // Stagger border appearances
            border-right-color 0.15s linear 0.10s,
            border-bottom-color 0.15s linear 0.20s;
  }

  &::after {
    border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
  }

  &:hover::after {
    border-top: 2px solid $cyan; // Shows border
    border-left-width: 2px; // Solid edges, invisible borders
    border-right-width: 2px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
    transition:
            transform 0.4s linear 0s,
            border-left-width 0s linear 0.35s; // Solid edge post-rotation
  }
}

.circle {
  border-radius: 100%;
  box-shadow: none;

  &::before,
  &::after {
    border-radius: 100%;
  }
}

.mixitup-control-active {
  &:focus,
  &:active {
    outline: unset;
  }
  outline: unset;
  color: $blue;
  &::after {
    border-top: 2px solid $blue; // Shows border
    border-left-width: 2px; // Solid edges, invisible borders
    border-right-width: 2px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
  }
  &::before {
    border-top-color: $blue; // Show borders
    border-right-color: $blue;
    border-bottom-color: $blue;
  }
}