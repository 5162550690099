@import "variables";
@import "mixitup-buttons";

@font-face {
    font-family: "B612";
    src: url('./../fonts/B612-Regular.woff') format("woff");
}

@font-face {
    font-family: "morganite thin";
    src: url('./../fonts/Morganite-Thin.woff') format("woff");
}

body {
    font-family: "B612", 'sans-serif';
    background-color: $blackish;
    color: $white;
    font-size: 14px;

    a {
        text-decoration: none;
        color: $blue;
        padding-left: 5px;

        &:visited,
        &:focus,
        &:active,
        &:link {
            text-decoration: none;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "morganite thin", 'sans-serif';
        text-align: center;
        letter-spacing: 3px;

        &.eggYellow {
            color: $eggYellow;
        }

        &.mediumGrey {
            color: $mediumGrey;
        }

        &.darkGrey {
            color: $darkGrey;
        }

        &.white {
            color: $white;
        }

        &.bg-mediumGrey {
            background-color: $mediumGrey;
        }
    }

    h1 {
        font-size: 28px;
        width: 90%;
        max-width: 400px;
        margin: 15px auto;

    }

    .certif {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .wrapper {
        width: 100%;
        max-width: 991px;
        margin-left: auto;
        margin-right: auto;
    }

    .logo {
        width: 40%;
        max-width: 375px;
        margin: auto;

        #name {
            fill: $blue;
        }

        #tv {
            fill: $cyan;
        }

        #dot {
            fill: $mediumGrey
        }

        #ext {
            fill: $white;
        }
    }


    table {
        width: 100%;
        margin-bottom: 10px;

        tr {
            display: flex;
            flex-direction: row;

            td {
                width: 45%;
                border: 1px solid $mediumGrey;
                padding: 3px;

                + td {
                    border-left: none;
                }

                &.first {
                    width: $thumbnailSize;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    img {
                        width: $thumbnailSize;
                    }
                }

                display: flex;

                /* Portrait */
                @media screen and (orientation: portrait) {
                    flex-direction: column;
                }
                /* Landscape */
                @media screen and (orientation: landscape) {
                    flex-direction: row;
                }
            }
        }

        .thumbnail-container {
            width: $thumbnailSize;
            height: $thumbnailSize;
            overflow: hidden;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            background-color: $mediumGrey;

            .thumbnail {
                width: $thumbnailSize;
                margin: auto;
            }
        }

        .singleProgram {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: 5%;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $blackishOP;
        display: flex;
        align-items: center;
        z-index: 2;
        overflow: auto;

        &.hidden {
            display: none;
        }

        .content {
            width: 90%;
            max-width: 500px;
            margin: auto;
            background-color: $mediumGrey;
            padding: 5px;
            color: $white;
            position: relative;

            .close {
                cursor: pointer;
                position: absolute;
                right: -10px;
                top: -10px;
                background-color: $white;
                width: 36px;
                height: 36px;
                border-radius: 50%;

                &:before, &:after {
                    position: absolute;
                    left: 17px;
                    top: 6px;
                    content: ' ';
                    height: 24px;
                    width: 3px;
                    background-color: $red;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }


            .infos {
                display: flex;
                flex-direction: row;

                .illus {
                    width: 50%;

                    img {
                        width: 100%;
                    }
                }

                .recap {
                    width: 50%;
                    padding-left: 35px;

                    &.full {
                        width: 100%;
                        padding-left: 0;
                    }

                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                }
            }

            .text {
                padding-top: 35px;
            }
        }
    }

    .now {
        table {
            margin: 0 auto 10px;
            width: 100%;
            max-width: 600px;

            td {
                width: 90%;
                flex-direction: row;
            }

        }
    }


    footer {
        letter-spacing: 3px;
        display: flex;
        flex-direction: column;
        text-align: center;

        h2 {
            font-size: 40px;
        }

        span {
            width: 100%;
            max-width: 400px;
            display: flex;
            justify-content: center;
            margin: 15px auto;

        }

        form {
            margin: 35px auto;

            .paypal {
                max-width: 100px;
            }
        }

        .mentions {
            margin-bottom: 35px;
        }

        #legal-terms {
            text-align: left;
        }


    }

    .programsList {
        .wrapper {
            max-width: 100vw;
            overflow: auto;
        }

        .singleTd {
            min-width: 150px;
        }
    }

    .message {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 400px;
        margin: 45px auto;

        span {
            margin: 5px;
        }
    }

    .error {
        border: 1px solid darkred;
        background-color: #FF000033;
    }

    .success {
        max-width: 95vw;
        border: 1px solid green;
        background-color: darkgreen;
        text-align: left;
    }
}

